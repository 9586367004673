body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




@media only screen and (max-width: 600px) {
  .aboutsec{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;}
  
      .aboutsec  h2{
          font-size: 24px;
          font-weight: 600;
      }
      .aboutsec div:nth-child(1) {
          background-color:#d5372c;
          width: 100%;
          color: #ffffff;
          padding: 20px 30px 50px 30px;
        }
        .aboutsec div:nth-child(1) p{
          color: #ffffff;
        }
        .aboutsec  div:nth-child(2) {
          background-color: #f2f1f6;
          width: 100%;
          color: #000000;
          padding: 20px 30px 50px 30px;
        }
        .aboutsec  div:nth-child(3) {
          background-color: #3c1ae8;
          width: 100%;
          color: #ffffff;
          padding: 20px 30px 50px 30px;
        }
  }
 


@media only screen and (min-width: 601px) {
  .aboutsec{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;
    }
  
      .aboutsec h2{
          font-size: 24px;
          font-weight: 700;
      }
      .aboutsec div:nth-child(1) {
          background-color:#d5372c;
          width: 33.33333333%;
          color: #ffffff;
          padding: 20px 30px 50px 30px;
        }
        .aboutsec div:nth-child(1) p{
          color: #ffffff;
        }

        .aboutsec div:nth-child(1) h2{
          color: #ffffff;
        }
        .aboutsec div:nth-child(1) p{
          color: #ffffff;
        }
        .aboutsec div:nth-child(2) {
          background-color: #ffffff;
          width: 33.33333333%;
          color: #000000;
          padding: 20px 30px 50px 30px;
        }
        .aboutsec div:nth-child(3) {
          background-color: #3c1ae8;
          width: 33.33333333%;
          color: #ffffff;
          padding: 20px 30px 50px 30px;
        }
        .aboutsec div:nth-child(3) p{
          color: #ffffff;
        }
        .aboutsec div:nth-child(3) h2{
          color: #ffffff;
        }
  }
 

.button{
  background-color: #000000!important;
  color: #ffffff;
  border-radius: 0px;
  border: none;
  padding: 10px 15px;
  position: absolute;
  bottom: 10px;
  width: 150px;
}

.service-details-card{
    padding: 30px 30px 10px 30px;
    box-shadow: 0px 8px 30px #6a6a6a1a;
    background-color: #ffffff;
    display: flex;
    align-items: flex-start;
    border-left: 5px solid var(--color-primary);
    min-height: 200px;
}

.productdesc{
  background-color: #ffffff;
  padding: 20px 20px;
  box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 20%);
  margin-bottom: 20px;
  border-radius: 2px;
}

.productdesc h5{
  color:#e00a0a;
  font-size: 20px;
  font-weight: 700;
}
.productdesc p{
  font-size: 14px;
  font-weight: 600;
}
.productdesc table th, td{
  font-size: 14px;
}
.mainlogo{
  width: 200px;
}


.service_box{
  background-color: #ffffff;
  box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 15%);
  border-radius: 5px 5px 0px 0px;
  padding: 10px 0px 0px 0px;
  text-align: center;
  min-height: 170px;
  border-bottom: 2px solid #e00a0a;
}

.service_box img{
  width: 70px;
}

.service_box h5{
  font-size: 16px;
  font-weight: 700;
  padding: 20px 0px 5px 0px;
  text-align: center;
}


.industries_box{
  background-color: #ffffff;
  box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 15%);
  border-radius: 5px 5px 0px 0px;
  padding: 0px 0px 10px 0px;
  text-align: center;
  min-height: 170px;
}

.industries_box img{
  width: 100%;
}

.industries_box h5{
  font-size: 20px;
  font-weight: 700;
  padding: 20px 0px 0px 0px;
  text-align: center;
}

.industries ul li{
  list-style-type: disc;
 }

 .industries h5{
  font-size: 18px;
  font-weight: 700;
  margin: 20px 0px 0px 0px;
 }

 .industries p{
  margin-bottom: 0px;

 }


 .industries h3{
  font-size: 30px;
  font-weight: 700;
  margin: 20px 0px 0px 0px;
 }


.client-wrapper-one a img{
  width: 150px;
  padding: 0px 0px;
  margin:20px 10px;
}

.contact-bg{
  background-image: url('https://planetronix.com/assets/images/breadcrumb/contact-us.jpg');
}
.aviation-bg{
  background-image: url('https://planetronix.com/assets/images/breadcrumb/aviation-breadcrumb.jpg');
}
.military-defence-bg{
  background-image: url('https://planetronix.com/assets/images/breadcrumb/military-defence.jpg');
}
.railway-bg{
  background-image: url('https://planetronix.com/assets/images/breadcrumb/railway-breadcrumb.jpg');
}

@media only screen and (max-width: 600px) {
  .footer-one-single-wized .quick-link-inner{
    display: flex;
    justify-content: flex-start;
    align-items: start;
    gap: 0px;
  }
}

@media only screen and (min-width: 601px) {
  .footer-one-single-wized .quick-link-inner{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 80px;
  }
}

.bg_banner-three .eight {
  background-image: url('https://planetronix.com/assets/images/banner/engine-maintainance.jpg');
  padding-top: 400px;
}

.bg_banner-three .eight-2 {
  background-image: url('https://planetronix.com/assets/images/banner/engine-maintainance.jpg');
  padding-top: 400px;
}
.bg_banner-three .eight-3 {
  background-image: url('https://planetronix.com/assets/images/banner/engine-maintainance.jpg');
  padding-top: 400px;
}




  
.banner-one .swiper-wrapper .swiper-slide{
          background-image: url('https://planetronix.com/assets/images/banner/engine-maintainance.jpg');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
      }
  
      .banner-one .swiper-wrapper .swiper-slide .two{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('https://planetronix.com/assets/images/banner/engine-maintainance.jpg');

      }

      .banner-one .swiper-wrapper .swiper-slide .three{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('https://planetronix.com/assets/images/banner/engine-maintainance.jpg');
      }

/* .underconstructionbg{
  background-image: url('https://planetronix.com/assets/images/underconstructionbg.jpg');
  background-repeat: no-repeat;
  min-height: 100vh;
} */




@media only screen and (max-width: 600px) {
  .underconstruction{
    margin: 50px 0px 100px 0px;   
  }

.underconstruction h1{
  font-size: 28px;
  line-height: 30px;
  color: #d5372c;
  margin-bottom: 0px;
  padding-bottom: 0px;
  text-align: center;
}
.underconstruction h2{
  margin-top: 0px;
  font-size: 23px;
  text-align: center;
}
.underconstruction h3{
  font-size: 38px;
  padding: 0px 0px 30px 0px;
  text-align: center;
} 
.underconstruction p{
margin: 0px;
font-size: 16px;
font-weight: 600;
}
.underconstruction p span{
font-weight: 600;
}
.underconstruction ul li{
list-style-type: disc;
}

}


@media only screen and (min-width: 601px) {
  .underconstruction{
    margin: 50px 0px 100px 0px;   
  }

  .underconstruction h1{
    font-size: 35px;
    line-height: 30px;
    color: #d5372c;
    margin-bottom: 0px;
    padding-bottom: 0px;
    text-align: center;
  }
  .underconstruction h2{
    margin-top: 0px;
    font-size: 28px;
    text-align: center;
  }
  .underconstruction h3{
    font-size: 45px;
    padding: 0px 0px 30px 0px;
    text-align: center;
  } 
  
.underconstruction p{
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  }
  
  .underconstruction p span{
  font-weight: 600;
  }
  .underconstruction ul li{
  list-style-type: disc;
  }
  
  }


  /* ============ HERO SECTION ============= */
.creative-hero--section{
	position: relative;
	overflow: hidden;
	height: 765px;
}
.creative-hero--section .bg {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: cover;
}
.creative-hero--section .bg-overlay {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-image: url('https://kaushikshowstar.com/amberclinics/wp-content/uploads/2025/03/herosec-design.png');
	background-position: 0 0;
	background-size: 56.5% 100%;
	background-repeat: no-repeat;
	animation: fadeIn 0.5s ease-in-out 0.3s forwards;
}
.auto-container {
	max-width: 1310px;
}

.auto-container {
	position: static;
	padding: 0 15px;
	margin: 0 auto;
	width: 100%;
	height: 100%;
}
.creative-hero--section .content-box {
	position: relative;
	max-width: 540px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	animation: fadeInUp 1s ease-in-out 0.6s forwards;
}
.creative-hero--section .content-box .hero-subtitle {
	display: block;
	font-size: 16px;
	line-height: 22px;
	color: #fff;
	font-weight: 700;
	letter-spacing: .8px;
	margin-bottom: 33px;
	opacity: 0;
	animation: fadeInUp 0.6s ease-in-out 0.6s forwards;
}
.creative-hero--section .content-box .hero-title {
	color: #fff;
	line-height: 1.1em;
	text-transform: capitalize;
	margin-bottom: 28px;
	font-size: 45px;
	font-weight: 700;
	opacity: 0;
	animation: fadeInUp 0.6s ease-in-out 0.9s forwards;
}
.creative-hero--section .content-box .hero-desc {
	color: #fff;
	margin-bottom: 40px;
	font-size: 16px;
	font-weight: 400;
	line-height: 32px;
	opacity: 0;
	animation: fadeInUp 0.6s ease-in-out 1.2s forwards;
}
.content-box .btn-box{
	opacity: 0;
	animation: fadeInUp 0.6s ease-in-out 1.6s forwards;
}
.content-box .btn-box .hero-cta {
	position: relative;
	font-size: 16px;
	line-height: 24px;
	padding: 20px 40px;
	font-weight: 600;
	overflow: hidden;
	color: #fff;
	background: #3c1ae8;
	letter-spacing: .8px;
	border-radius: 7px;
	text-decoration: none;
	display: inline-flex;
	justify-content: center;
	text-align: center;
	white-space: nowrap;
	transition: all .3s ease;
}

.content-box .btn-box .hero-cta:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	content: "";
	border-radius: 7px;
	transform: scaleX(0);
	transform-origin: top right;
	transition: transform .5s cubic-bezier(.86, 0, .07, 1);
	background-color: #fff;
}

.content-box .btn-box .hero-cta:hover {
	box-shadow: 0 10px 30px rgba(0, 0, 0, .1);
	color: #16243e;
}

.content-box .btn-box .hero-cta:hover:before {
	transform: scaleX(1);
	transform-origin: bottom left;
}




/*cta button start*/


.booknow-box{
	opacity: 0;
	animation: fadeInUp 0.6s ease-in-out 1.6s forwards;
}
.booknow-box .booknow-cta {
	position: relative;
	font-size: 16px;
	line-height: 24px;
	padding: 20px 40px;
	font-weight: 600;
	overflow: hidden;
	color: #fff;
	background: #134cb3;
	letter-spacing: .8px;
	border-radius: 7px;
	text-decoration: none;
	display: inline-flex;
	justify-content: center;
	text-align: center;
	white-space: nowrap;
	transition: all .3s ease;
}

.booknow-box .booknow-cta:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	content: "";
	border-radius: 7px;
	transform: scaleX(0);
	transform-origin: top right;
	transition: transform .5s cubic-bezier(.86, 0, .07, 1);
	background-color: #fff;
}

.booknow-box .booknow-cta:hover {
	box-shadow: 0 10px 30px rgba(0, 0, 0, .1);
	color: #16243e;
}

.booknow-box .booknow-cta:hover:before {
	transform: scaleX(1);
	transform-origin: bottom left;
}


.booknow-box .booknow-cta .cta-booknow-title{
	position: relative;
	display: flex;
	align-items: center;
}



/*cta button end*/




.btn-box .hero-cta .cta-title{
	position: relative;
	display: flex;
	align-items: center;
}
.btn-box .hero-cta .cta-title i{
	position: relative;
	display: block;
	margin-left: 10px;
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 40px, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}


.creditd a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
        font-size: 16px;
}

/* =================== RESPONSIVE IPAD PRO ============================= */
@media (max-width: 1366px) {
    .creative-hero--section .bg-overlay {
        background-image: none;
        background-color: #16243e;
        mix-blend-mode: hard-light;
    }
}
/* =================== RESPONSIVE IPAD  ============================= */
@media (max-width: 991px) {
	.creative-hero--section{
		    height: auto;
    padding: 130px 0px;
	}

    .creative-hero--section .content-box{
    	max-width: 100%;
    	    text-align: center;
    }
}
/* =================== RESPONSIVE IPHONE  ============================= */
@media (max-width: 575px) {
    .creative-hero--section .content-box .hero-title {
        font-size: 44px;
    }
    .creative-hero--section .content-box .hero-desc{
    	font-size: 15px;
    }
}

.bg-image{
  background-image: url('../public/assets/images/aviation-banner.jpg');

}

.rts-breadcrumb-area .title{
  font-size: 35px;
}

.main-header .nav-main ul li a {
  display: block;
  color: rgb(28, 37, 57);
  font-size: 16px;
  font-weight: 500;
  position: relative;
  min-width: max-content;
  padding: 20px 0px;
  transition: var(--transition);
}

.header-main-one .thumbnail a {
  padding: 20px 0;
  display: block;
  max-width: max-content;
}

/*  product Specfication */

.description {
  background-color: #ffffff;
  color: #000000;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  z-index: 2;
  box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 20%);
  animation: fadeInUp 0.6s ease-in-out 0.9s forwards;
  margin-bottom: 20px;
  min-height: 200px;
}
.description h5{
  font-size: 18px;
  font-weight: 700;
}

.description ul{
  padding-left: 0px;
}
.description ul li{
  margin: 0px;
}



.description ul li:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  color: var(--color-primary);
  display: inline-block;
  padding-right: 6px;
  vertical-align: middle;
}

.description:hover{
  transform: scale(1.03);
  transition: 0.3s;
  -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
}

.rts-single-wized .single-categories li a {
     display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgb(93, 102, 111);
    font-weight: 500;
    padding: 5px 15px;
    background: rgb(255, 255, 255);
    transition: 0.3s;
    border-radius: 5px;
}

.rts-single-wized {
  padding: 30px;
}